import React, { useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import CreateChapter from './components/CreateChapter';
import CreateManga from './components/CreateManga';
import Dashboard from './components/Dashboard';
import EditManga from './components/EditManga';
import ManageHeader from './components/ManageHeader';
import ManageMangas from './components/ManageMangas';
import Login from './login/Login';
import Register from './login/Register';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('authToken'));

  const handleLogin = (token) => {
    localStorage.setItem('authToken', token);
    setIsAuthenticated(true);
  };

  return (
    <Router>
      <Routes>
        {/* Rotas existentes */}
        <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} />
        <Route path="/create-manga" element={isAuthenticated ? <CreateManga /> : <Navigate to="/login" />} />
        <Route path="/create-chapter" element={isAuthenticated ? <CreateChapter /> : <Navigate to="/login" />} />
        <Route path="/projetos" element={isAuthenticated ? <ManageMangas /> : <Navigate to="/login" />} />
        <Route path="/projetos/:name" element={isAuthenticated ? <EditManga /> : <Navigate to="/login" />} />

        {/* Nova rota para gerenciar menus */}
        <Route path="/manage-header" element={isAuthenticated ? <ManageHeader /> : <Navigate to="/login" />} />

        {/* Rotas de login e registro */}
        <Route path="/login" element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <Login onLogin={handleLogin} />} />
        <Route path="/register" element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <Register />} />
        
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
