import React, { useState } from 'react';
import '../style/CreateManga.css';

function CreateManga() {
  const [title, setTitle] = useState('');
  const [alternativeTitle, setAlternativeTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [artist, setArtist] = useState('');
  const [genres, setGenres] = useState('');
  const [type, setType] = useState('Manga');
  const [coverImage, setCoverImage] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [synopsis, setSynopsis] = useState('');
  const [message, setMessage] = useState('');
  const [errorDetails, setErrorDetails] = useState('');

  const token = localStorage.getItem('authToken'); // Garantir que 'authToken' está correto e seguro

  // Obtém a URL da API a partir das variáveis de ambiente
  const apiUrl = process.env.REACT_APP_API_URL || 'https://api.arthurcomics.com/api/mangas';

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append('title', title);
    formData.append('altTitle', alternativeTitle);
    formData.append('author', author);
    formData.append('artist', artist);
    formData.append('genres', genres);
    formData.append('type', type);
    formData.append('synopsis', synopsis);
    if (coverImage) {
      formData.append('coverImage', coverImage);
    }
    if (bannerImage) {
      formData.append('bannerImage', bannerImage);
    }
  
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setMessage('Mangá criado com sucesso!');
        setErrorDetails('');
        // Limpar os campos após a criação
        setTitle('');
        setAlternativeTitle('');
        setAuthor('');
        setArtist('');
        setGenres('');
        setType('Manga');
        setSynopsis('');
        setCoverImage(null);
        setBannerImage(null);
      } else {
        setMessage('Erro ao criar mangá.');
        setErrorDetails(`Detalhes do erro: ${data.error}`);
      }
    } catch (error) {
      console.error('Erro ao criar mangá:', error);
      setMessage('Erro ao criar mangá. Tente novamente.');
      setErrorDetails(`Erro do cliente: ${error.message}`);
    }
  };

  return (
    <div className="create-manga-container">
      <h1>Criar Mangá</h1>
      {message && <p className="message">{message}</p>}
      {errorDetails && <pre className="error-details">{errorDetails}</pre>}
      <form onSubmit={handleSubmit}>
        <label>
          Nome:
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </label>
        <label>
          Título Alternativo:
          <input
            type="text"
            value={alternativeTitle}
            onChange={(e) => setAlternativeTitle(e.target.value)}
          />
        </label>
        <label>
          Autor(es):
          <input
            type="text"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
          />
        </label>
        <label>
          Artista(s):
          <input
            type="text"
            value={artist}
            onChange={(e) => setArtist(e.target.value)}
          />
        </label>
        <label>
          Gêneros (separados por vírgula):
          <input
            type="text"
            value={genres}
            onChange={(e) => setGenres(e.target.value)}
          />
        </label>
        <label>
          Tipo:
          <select
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <option value="Manga">Manga</option>
            <option value="Manhua">Manhua</option>
            <option value="Manhwa">Manhwa</option>
          </select>
        </label>
        <label>
          Sinopse:
          <textarea
            value={synopsis}
            onChange={(e) => setSynopsis(e.target.value)}
            required
          />
        </label>
        <label>
          Imagem de Capa:
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setCoverImage(e.target.files[0])}
          />
        </label>
        <label>
          Imagem de Banner:
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setBannerImage(e.target.files[0])}
          />
        </label>
        <button type="submit">Criar Mangá</button>
      </form>
    </div>
  );
}

export default CreateManga;
