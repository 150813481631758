// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manga-list-container {
    padding: 20px;
  }
  
  .manga-grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 20px;
  }
  
  .manga-item {
    text-align: center;
  }
  
  .manga-cover {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  h3 {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  
  .manage-button {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .manage-button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/style/ManageMangas.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,aAAa;IACb,qCAAqC;IACrC,SAAS;EACX;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,qBAAqB;IACrB,gBAAgB;IAChB,iBAAiB;IACjB,yBAAyB;IACzB,WAAW;IACX,qBAAqB;IACrB,kBAAkB;IAClB,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".manga-list-container {\n    padding: 20px;\n  }\n  \n  .manga-grid {\n    display: grid;\n    grid-template-columns: repeat(8, 1fr);\n    gap: 20px;\n  }\n  \n  .manga-item {\n    text-align: center;\n  }\n  \n  .manga-cover {\n    width: 100%;\n    height: auto;\n    border-radius: 8px;\n  }\n  \n  h3 {\n    margin-top: 10px;\n    font-size: 16px;\n    font-weight: bold;\n  }\n  \n  .manage-button {\n    display: inline-block;\n    margin-top: 10px;\n    padding: 8px 12px;\n    background-color: #007bff;\n    color: #fff;\n    text-decoration: none;\n    border-radius: 4px;\n    transition: background-color 0.3s;\n  }\n  \n  .manage-button:hover {\n    background-color: #0056b3;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
