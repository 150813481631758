import axios from 'axios';
import React, { useEffect, useState } from 'react';
import '../style/CreateChapter.css'; // Importar o CSS

function CreateChapter() {
  const [books, setBooks] = useState([]);
  const [chapters, setChapters] = useState({});
  const [selectedBook, setSelectedBook] = useState('');
  const [chapterNumber, setChapterNumber] = useState('');
  const [content, setContent] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Buscar a lista de livros e capítulos ao montar o componente
    const fetchBooks = async () => {
      try {
        const booksResponse = await axios.get('https://api.arthurcomics.com/api/books');
        setBooks(booksResponse.data);

        const chaptersResponse = await axios.get('https://api.arthurcomics.com/api/chapters');
        const chaptersData = chaptersResponse.data.reduce((acc, chapter) => {
          if (!acc[chapter.book]) {
            acc[chapter.book] = [];
          }
          acc[chapter.book].push(chapter);
          return acc;
        }, {});
        setChapters(chaptersData);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };

    fetchBooks();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.arthurcomics.com/api/chapters', { book: selectedBook, chapterNumber, content });
      setMessage(`Capítulo criado com sucesso no livro: ${response.data.book}`);

      // Atualizar a lista de capítulos
      setChapters((prevChapters) => {
        const newChapters = { ...prevChapters };
        if (!newChapters[selectedBook]) {
          newChapters[selectedBook] = [];
        }
        newChapters[selectedBook].push(response.data);
        return newChapters;
      });
    } catch (error) {
      setMessage('Erro ao criar o capítulo: ' + error.response?.data?.error || error.message);
    }
  };

  const handleDelete = async (bookTitle, chapterId) => {
    try {
      await axios.delete(`https://api.arthurcomics.com/api/chapters/${chapterId}`);
      setChapters((prevChapters) => {
        const newChapters = { ...prevChapters };
        newChapters[bookTitle] = newChapters[bookTitle].filter(chapter => chapter._id !== chapterId);
        return newChapters;
      });
      setMessage('Capítulo deletado com sucesso');
    } catch (error) {
      setMessage('Erro ao deletar o capítulo: ' + error.response?.data?.error || error.message);
    }
  };

  return (
    <div className="create-chapter-container">
      <h1>Criar Capítulo</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Livro:
          <select
            value={selectedBook}
            onChange={(e) => setSelectedBook(e.target.value)}
            required
          >
            <option value="" disabled>Selecione um livro</option>
            {books.map((book) => (
              <option key={book._id} value={book.title}>
                {book.title}
              </option>
            ))}
          </select>
        </label>
        <label>
          Número do Capítulo:
          <input
            type="number"
            value={chapterNumber}
            onChange={(e) => setChapterNumber(e.target.value)}
            required
          />
        </label>
        <label>
          Conteúdo do Capítulo:
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          />
        </label>
        <button type="submit">Criar Capítulo</button>
      </form>
      {message && <p>{message}</p>}

      <div className="chapters-list">
        <h2>Capítulos Criados</h2>
        {Object.keys(chapters).map((bookTitle) => (
          <div key={bookTitle} className="book-chapters">
            <h3>{bookTitle}</h3>
            <ul>
              {chapters[bookTitle].map((chapter) => (
                <li key={chapter._id}>
                  Capítulo {chapter.chapterNumber}
                  <button
                    className="delete-button"
                    onClick={() => handleDelete(bookTitle, chapter._id)}
                  >
                    Apagar
                  </button>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CreateChapter;
