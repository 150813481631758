import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../style/EditManga.css';

const token = localStorage.getItem('authToken');

function EditManga() {
  const { name } = useParams();
  const [manga, setManga] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [title, setTitle] = useState('');
  const [alternativeTitle, setAlternativeTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [artist, setArtist] = useState('');
  const [genres, setGenres] = useState('');
  const [type, setType] = useState('Manga');
  const [synopsis, setSynopsis] = useState('');
  const [message, setMessage] = useState('');
  const [newChapter, setNewChapter] = useState('');
  const [chapterFile, setChapterFile] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [error, setError] = useState('');
  const [editingChapterId, setEditingChapterId] = useState(null);
  const [editingChapterName, setEditingChapterName] = useState('');
  const [thumbnailFile, setThumbnailFile] = useState(null);

  useEffect(() => {
    if (!name) {
      setError('Nome do mangá não fornecido.');
      return;
    }

    fetch(`https://api.arthurcomics.com/api/mangas`)
      .then(response => response.json())
      .then(data => {
        const currentManga = data.find(m => m.title.toLowerCase() === name.toLowerCase());
        if (currentManga) {
          setManga(currentManga);
          setTitle(currentManga.title);
          setAlternativeTitle(currentManga.alternativeTitle || '');
          setAuthor(currentManga.author.join(', '));
          setArtist(currentManga.artist.join(', '));
          setGenres(currentManga.genres.join(', '));
          setType(currentManga.type);
          setSynopsis(currentManga.synopsis || '');
          setCoverImage(currentManga.coverImage);
          setBannerImage(currentManga.bannerImage);
        } else {
          setError('Mangá não encontrado.');
        }
      })
      .catch(error => setError(`Erro ao buscar mangá: ${error.message}`));

    fetch(`https://api.arthurcomics.com/api/chapters`)
      .then(response => response.json())
      .then(data => {
        const mangaChapters = data.filter(chapter => chapter.manga.toLowerCase() === name.toLowerCase());
        setChapters(mangaChapters);
      })
      .catch(error => setError(`Erro ao buscar capítulos: ${error.message}`));
  }, [name]);

  const handleUpdateManga = async (e) => {
    e.preventDefault();

    const updatedManga = {
      title,
      alternativeTitle,
      author: author.split(',').map(a => a.trim()),
      artist: artist.split(',').map(a => a.trim()),
      genres: genres.split(',').map(genre => genre.trim()),
      type,
      synopsis,
    };

    try {
      const response = await fetch(`https://api.arthurcomics.com/api/mangas/${manga._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(updatedManga),
      });

      if (response.ok) {
        setMessage('Mangá atualizado com sucesso!');
      } else {
        const text = await response.text();
        setError(`Erro ao atualizar mangá: ${text}`);
      }
    } catch (error) {
      setError(`Erro ao atualizar mangá: ${error.message}`);
    }
  };

  const handleFileChange = async (e, type) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append(type, file);

    try {
      const response = await fetch(`https://api.arthurcomics.com/api/mangas/${manga._id}/images`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        const updatedManga = await response.json();
        if (type === 'coverImage') setCoverImage(updatedManga.coverImage);
        if (type === 'bannerImage') setBannerImage(updatedManga.bannerImage);
        setMessage(`${type === 'coverImage' ? 'Capa' : 'Banner'} atualizado com sucesso!`);
      } else {
        const errorText = await response.text();
        setError(`Erro ao atualizar ${type === 'coverImage' ? 'capa' : 'banner'}: ${errorText}`);
      }
    } catch (error) {
      setError(`Erro ao atualizar ${type}: ${error.message}`);
    }
  };

  const handleAddChapter = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('chapterNumber', newChapter.trim());
    formData.append('manga', name);
    if (chapterFile) formData.append('chapterFile', chapterFile);

    try {
      const response = await fetch('https://api.arthurcomics.com/api/chapters', {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setMessage('Capítulo adicionado com sucesso!');
        setNewChapter('');
        setChapterFile(null);
        setChapters([...chapters, data]);
      } else {
        const text = await response.text();
        setError(`Erro ao adicionar capítulo: ${text}`);
      }
    } catch (error) {
      setError(`Erro ao adicionar capítulo: ${error.message}`);
    }
  };

  const handleDeleteChapter = async (chapterId) => {
    if (!window.confirm('Tem certeza que deseja excluir este capítulo?')) return;

    try {
      const response = await fetch(`https://api.arthurcomics.com/api/chapters/${chapterId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        setChapters(chapters.filter(chapter => chapter._id !== chapterId));
        setMessage('Capítulo excluído com sucesso!');
      } else {
        setError('Erro ao excluir capítulo.');
      }
    } catch (error) {
      setError(`Erro ao excluir capítulo: ${error.message}`);
    }
  };

  const handleUpdateChapterName = async (chapterId) => {
    try {
      const response = await fetch(`https://api.arthurcomics.com/api/chapters/${chapterId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ chapterNumber: editingChapterName }),
      });

      if (response.ok) {
        const updatedChapters = chapters.map(chapter => {
          if (chapter._id === chapterId) {
            return { ...chapter, chapterNumber: editingChapterName };
          }
          return chapter;
        });
        setChapters(updatedChapters);
        setEditingChapterId(null);
        setEditingChapterName('');
      } else {
        setError('Erro ao atualizar o nome do capítulo.');
      }
    } catch (error) {
      setError(`Erro ao atualizar o nome do capítulo: ${error.message}`);
    }
  };

  const handleThumbnailUpload = async (chapterId, file) => {
    const formData = new FormData();
    formData.append('thumbnail', file);

    try {
      const response = await fetch(`https://api.arthurcomics.com/api/chapters/${chapterId}/thumbnail`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        setMessage('Thumbnail do capítulo atualizado com sucesso!');
      } else {
        setError('Erro ao atualizar a thumbnail.');
      }
    } catch (error) {
      setError(`Erro ao atualizar a thumbnail: ${error.message}`);
    }
  };

  if (error) return <div className="error">{error}</div>;
  if (!manga) return <div>Carregando...</div>;

  return (
    <div className="edit-manga-container">
      <h1>Editar Mangá - {manga.title}</h1>
      {message && <p className="message">{message}</p>}
      <form onSubmit={handleUpdateManga} className='editinfo'>
        <label>
          Nome:
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </label>
        <label>
          Título Alternativo:
          <input
            type="text"
            value={alternativeTitle}
            onChange={(e) => setAlternativeTitle(e.target.value)}
          />
        </label>
        <label>
          Autor(es):
          <input
            type="text"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
          />
        </label>
        <label>
          Artista(s):
          <input
            type="text"
            value={artist}
            onChange={(e) => setArtist(e.target.value)}
          />
        </label>
        <label>
          Gêneros (separados por vírgula):
          <input
            type="text"
            value={genres}
            onChange={(e) => setGenres(e.target.value)}
          />
        </label>
        <label>
          Tipo:
          <select
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <option value="Manga">Manga</option>
            <option value="Manhua">Manhua</option>
            <option value="Manhwa">Manhwa</option>
          </select>
        </label>
        <label>
          Sinopse:
          <textarea
            value={synopsis}
            onChange={(e) => setSynopsis(e.target.value)}
          />
        </label>
        <button type="submit">Atualizar Mangá</button>
      </form>
      <h2>Editar Capa e Banner</h2>
      <div className="images-section">
        <div className="image-edit">
          <h3>Capa Atual</h3>
          {coverImage && <img src={`https://api.arthurcomics.com/${coverImage}`} alt="Capa do Mangá" />}
          <input type="file" onChange={(e) => handleFileChange(e, 'coverImage')} />
        </div>
        <div className="image-edit">
          <h3>Banner Atual</h3>
          {bannerImage && <img src={`https://api.arthurcomics.com/${bannerImage}`} alt="Banner do Mangá" />}
          <input type="file" onChange={(e) => handleFileChange(e, 'bannerImage')} />
        </div>
      </div>
      <h2>Adicionar Capítulo</h2>
      <form onSubmit={handleAddChapter} className='chaptercreate'>
        <label>
          Número do Capítulo:
          <input
            type="text"
            value={newChapter}
            onChange={(e) => setNewChapter(e.target.value)}
            required
          />
        </label>
        <label>
          Arquivo do Capítulo:
          <input
            type="file"
            onChange={(e) => setChapterFile(e.target.files[0])}
            required
          />
        </label>
        <button type="submit">Adicionar Capítulo</button>
      </form>

      <h2>Capítulos</h2>
      <ul>
        {chapters.map((chapter) => (
          <li key={chapter._id}>
            {editingChapterId === chapter._id ? (
              <>
                <input
                  type="text"
                  value={editingChapterName}
                  onChange={(e) => setEditingChapterName(e.target.value)}
                />
                <button onClick={() => handleUpdateChapterName(chapter._id)}>Salvar</button>
              </>
            ) : (
              <>
                {chapter.chapterNumber}
                <button onClick={() => {
                  setEditingChapterId(chapter._id);
                  setEditingChapterName(chapter.chapterNumber);
                }}>Editar Nome</button>
              </>
            )}
            <button onClick={() => handleDeleteChapter(chapter._id)}>Excluir</button>
            <div>
              <label>Thumbnail:</label>
              <input
                type="file"
                onChange={(e) => handleThumbnailUpload(chapter._id, e.target.files[0])}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default EditManga;
