import React, { useEffect, useState } from 'react';

function ManageHeader() {
  const [menus, setMenus] = useState([]);
  const [newMenu, setNewMenu] = useState({ label: '', url: '' });
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchMenus();
  }, []);

  const fetchMenus = async () => {
    try {
      const response = await fetch('http://api.arthurcomics.com/api/menus');
      const data = await response.json();
      setMenus(data || []);
    } catch (error) {
      console.error('Erro ao carregar menus:', error);
      setMessage('Erro ao carregar menus.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMenu({ ...newMenu, [name]: value });
  };

  const handleAddMenu = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://api.arthurcomics.com/api/menus', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newMenu),
      });
      const data = await response.json();
      if (data && data._id) {
        setMenus([...menus, data]);
        setNewMenu({ label: '', url: '' });
        setMessage('Menu adicionado com sucesso!');
      } else {
        setMessage('Erro ao adicionar menu.');
      }
    } catch (error) {
      setMessage('Erro ao adicionar menu.');
      console.error('Erro ao adicionar menu:', error);
    }
  };

  const handleDeleteMenu = async (id) => {
    try {
      const response = await fetch(`http://api.arthurcomics.com/api/menus/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        setMenus(menus.filter(menu => menu._id !== id));
        setMessage('Menu excluído com sucesso!');
      } else {
        setMessage('Erro ao excluir menu.');
      }
    } catch (error) {
      setMessage('Erro ao excluir menu.');
      console.error('Erro ao excluir menu:', error);
    }
  };

  return (
    <div className="manage-header-container">
      <h1>Gerenciar Menus do Header</h1>
      {message && <p className="message">{message}</p>}
      <form onSubmit={handleAddMenu}>
        <label>
          Nome do Menu:
          <input
            type="text"
            name="label"
            placeholder="Nome do Menu"
            value={newMenu.label}
            onChange={handleInputChange}
            required
          />
        </label>
        <label>
          URL do Menu:
          <input
            type="text"
            name="url"
            placeholder="URL do Menu"
            value={newMenu.url}
            onChange={handleInputChange}
            required
          />
        </label>
        <button type="submit">Adicionar Menu</button>
      </form>
      <ul className="menu-list">
        {menus.map(menu => (
          <li key={menu._id}>
            {menu.label} - {menu.url}
            <button onClick={() => handleDeleteMenu(menu._id)}>Excluir</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ManageHeader;
