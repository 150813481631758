import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../style/ManageMangas.css';

function ManageMangas() {
  const [mangas, setMangas] = useState([]);

  useEffect(() => {
    fetch('https://api.arthurcomics.com/api/mangas')
      .then(response => response.json())
      .then(data => setMangas(data))
      .catch(error => console.error('Erro ao buscar mangás:', error));
  }, []);

  return (
    <div className="manga-list-container">
      <h1>Gerenciar Mangás</h1>
      <div className="manga-grid">
        {mangas.map(manga => (
          <div key={manga._id} className="manga-item">
            <img
              src={`https://api.arthurcomics.com/${manga.coverImage}`}
              alt={manga.title}
              className="manga-cover"
            />
            <h3>{manga.title}</h3>
            <Link to={`/projetos/${manga.title}`} className="manage-button">
              Gerenciar
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ManageMangas;
